<template>
  <div class="rule">
    <div class="mask">
      <a-row type="flex" justify="center" style="width:100%">
        <a-col :xl="9" :lg="12" :md="15" :span="22">
          <RuleSearchInput></RuleSearchInput>
        </a-col>
      </a-row>
    </div>
    <a-row type="flex" justify="center" style="width: 100%;margin-top: 40px">
      <a-col :xl="18" :lg="18" :md="18" :span="22">
        <h3 class="title">最新规则</h3>
        <hr>
        <a-row style="font-size: 14px;color: #000;text-align: left">
          <a-col :md="12" :span="24">
            <ul class="nor_ul">
              <p class="subTitle">公告</p>
              <li v-for="(item,index) in noitce" :key="'notice' + index">
                <a :href="screenWidth > 750 ? item.link : item.mpLink">{{item.title}}</a>
              </li>
            </ul>
          </a-col>
          <a-col :md="12" :span="24">
            <ul class="nor_ul">
              <p class="subTitle">规则</p>
              <li v-for="(item,index) in rulesList" :key="'rule' + index">
                <a :href="screenWidth > 750 ? item.link : item.mpLink">{{item.title}}</a>
              </li>
            </ul>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>

<script>

import RuleSearchInput from 'views/rule/search/RuleSearchInput'

export default {
  name: 'Rule',
  components: { RuleSearchInput },
  data() {
    return {
      screenWidth: document.body.clientWidth,
      noitce: [{
        title: 'Vllshop关于商品、订单通知及申请FAQ',
        link: '/RuleHome',
        mpLink: '/notice/ShopFAQ'
      },
      {
        title: 'Vllshop关于发布特殊品类商品信息公告',
        link: '/RuleHome?activeKey=1&current=1000',
        mpLink: '/notice/ReleaseSpecialProducts'
      },
      {
        title: 'Vllshop关于退货商品',
        link: '/RuleHome?activeKey=1&current=1002',
        mpLink: '/notice/ReturnGoods'
      },
      {
        title: 'Vllshop关于知识产权投诉公告',
        link: '/RuleHome?activeKey=1&current=1003',
        mpLink: '/notice/ReturnGoods'
      }],
      rulesList: [
        {
          title: 'Vllshop店铺限定规则',
          link: '/RuleHome?activeKey=2&current=1000&ruleId=2000',
          mpLink: '/rule/ShopLimitRule'
        },
        {
          title: '交易安全规则',
          link: '/RuleHome?activeKey=2&current=1000&ruleId=2001',
          mpLink: '/rule/TransactionRule'
        },
        {
          title: '禁售规则',
          link: '/RuleHome?activeKey=2&current=1000&ruleId=2002',
          mpLink: '/rule/ShopLimitRule'
        },
        {
          title: '商品违法处罚规则',
          link: '/RuleHome?activeKey=2&current=1000&ruleId=2003',
          mpLink: '/rule/PunishRule'
        },
        {
          title: '行业管理规范',
          link: '/RuleHome?activeKey=2&current=1000&ruleId=2004',
          mpLink: '/rule/IndustryRule'
        },
        {
          title: '知识产权规则',
          link: '/RuleHome?activeKey=2&current=1000&ruleId=2005',
          mpLink: '/rule/IntellectualPropertyRule'
        }
      ]
    }
  },
  mounted() {
    const that = this
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth
        that.screenWidth = window.screenWidth
      })()
    }
  },
  destroyed() {
    window.onresize = null
  }
}
</script>

<style scoped lang="scss">
.nor_ul{
 li{
   list-style: disc;
   margin-left: 15px;
   margin-bottom: 15px;
   cursor: pointer;
 }
}
.subTitle{
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 15px;
  margin-top: 10px;
  color: #37404f;
}
.title{
  font-weight: 600;
  font-size: 22px;
  line-height: 31px;
  color: #191a1c;
  text-align: left;
}
.mask{
  width: 100%;
  height: 240px;
  position: relative;
  background-image: url('/images/Mask_Group.png');
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
::v-deep .ant-input-lg{
  height: 54px !important;
}
::v-deep .mask .ant-btn-lg{
  height: 54px !important;
  width: 80px;
  font-size: 25px;
}
</style>
